import type { NavigationGuard } from 'vue-router';
import { useAuthStore } from '@/stores/auth-store';

/**
 * Router middleware for auth guard
 */
export const authGuard: NavigationGuard = async (to) => {
   const authStore = useAuthStore();

   /*
    * Initialize the auth store, fetch and get user info
    */
   if (!authStore.isInitialized) {
      await authStore.init();
   }

   if (to.meta.requiresAuth && !authStore.isAuthenticated) {
      return '/login';
   }
};
