export const IconLib = {
   actions: {
      close: 'ic:baseline-close',
      cancel: 'ph:x',
      loading: 'uil:sync',
      search: 'uil:search',
      plus: 'uil:plus',
      minus: 'uil:minus',
      eye: {
         open: 'material-symbols:visibility-off-outline-rounded',
         close: 'material-symbols:visibility-outline',
      },
      camera: 'uil:camera',
      edit: 'ph:pencil-simple-line',
      copy: 'uil:copy',
      check: 'ph:check',
      expand: 'mdi:arrow-expand',
      collapse: 'mdi:arrow-collapse',
      save: {
         filled: 'flowbite:floppy-disk-alt-solid',
         outline: 'flowbite:floppy-disk-alt-outline',
      },
      delete: 'ph:trash',
   },
   sidebar: {
      home: 'uil:home-alt',
      map: 'uil:map',
      chat: 'uil:comment-message',
      profile: 'uil:user-circle',
      settings: 'uil:cog',
      signOut: 'uil:signout',
   },
   menuToggle: {
      // sm screen menu toggle button
      menu: 'uil:align-justify',
      closeMenu: 'uil:times',
   },
   loading: {
      spinner: 'svg-spinners:180-ring-with-bg',
   },
   error: {
      triangle: 'uil:exclamation-triangle',
   },
   map: {
      marker: 'heroicons:map-pin-16-solid',
   },
   weather: {
      // weather widget
      humidity: 'mdi:water-outline',
      windSpeed: 'mdi:weather-windy',
      clouds: 'mdi:weather-cloudy',
      weatherCondition: {
         Clear: 'mdi:weather-sunny',
         Clouds: 'mdi:weather-cloudy',
         Rain: 'mdi:weather-rainy',
         Drizzle: 'mdi:weather-partly-rainy',
         Thunderstorm: 'mdi:weather-lightning-rainy',
         Snow: 'mdi:weather-snowy',
         Mist: 'mdi:weather-fog',
         Fog: 'mdi:weather-fog',
         Haze: 'mdi:weather-hazy',
         Dust: 'mdi:weather-dust',
         Smoke: 'mdi:weather-dust',
         Squall: 'mdi:weather-windy',
         Tornado: 'mdi:weather-tornado',
         DEFAULT: 'mdi:weather-cloudy',
      },
   },
   watchLocation: {
      home: 'ph:house',
      office: 'ph:briefcase',
      pin: 'ph:map-pin',
   },
};
