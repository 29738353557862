<script setup lang="ts">
import { IconLib } from '@/assets/icon-library.ts';
import { useAuthStore } from '@/stores/auth-store.ts';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';

/* ---------------------------------------------------------------------------------------------- */

const authStore = useAuthStore();
const router = useRouter();

/* ---------------------------------------------------------------------------------------------- */

/**
 * Handles sign out and redirects to login page
 */
async function handleSignOut() {
   const success = await authStore.signOut();
   if (success) {
      await router.push('/login');
   }
}

/* ---------------------------------------------------------------------------------------------- */
</script>

<template>
   <div
      class="flex flex-col items-center gap-4 overflow-auto overflow-x-hidden px-2 py-10 size-full"
   >
      <!-- logo -->
      <RouterLink to="/">
         <img
            src="../../../assets/images/logo/logo-black.svg"
            alt="Logo"
            class="h-16"
         >
      </RouterLink>

      <!-- navigation buttons -->
      <div class="mt-10 flex flex-1 flex-col gap-4">
         <RouterLink to="/" class="button-base" active-class="button-active">
            <Icon
               :icon="IconLib.sidebar.home"
               class="size-5 md:size-6"
            />
         </RouterLink>

         <RouterLink to="/maps" class="button-base" active-class="button-active">
            <Icon
               :icon="IconLib.sidebar.map"
               class="size-5 md:size-6"
            />
         </RouterLink>

         <button class="button-base">
            <Icon
               :icon="IconLib.sidebar.chat"
               class="size-5 md:size-6"
            />
         </button>

         <RouterLink to="/profile" class="button-base" active-class="button-active">
            <Icon
               :icon="IconLib.sidebar.profile"
               class="size-5 md:size-6"
            />
         </RouterLink>

         <RouterLink to="/settings" class="button-base" active-class="button-active">
            <Icon
               :icon="IconLib.sidebar.settings"
               class="size-5 md:size-6"
            />
         </RouterLink>
      </div>

      <!-- sign out button -->
      <button
         class="button-base"
         @click="handleSignOut"
      >
         <Icon
            :icon="IconLib.sidebar.signOut"
            class="size-5 md:size-6"
         />
      </button>
   </div>
</template>

<style scoped lang="postcss">
.button-base {
   @apply flex items-center justify-center;
   @apply rounded-full text-black p-2.5 hover:bg-primary hover:text-white md:p-3;
   @apply transition-all duration-200;
}

.button-active {
   @apply text-white bg-primary;
}
</style>
