import { useAuthStore } from '@/stores/auth-store';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/home-page.vue';
import { authGuard } from './auth-guard';

/* ---------------------------------------------------------------------------------------------- */

// Test UI routes
const testRoutes = [
   {
      path: '/test/ui',
      name: 'test-ui',
      component: () => import('@/views/test/ui/test-ui-page.vue'),
      meta: {
         requiresAuth: true,
      },
   },
];

/* ---------------------------------------------------------------------------------------------- */

const router = createRouter({
   history: createWebHistory(import.meta.env.BASE_URL),
   routes: [
      {
         path: '/',
         name: 'home',
         component: HomePage,
      },
      {
         path: '/about',
         name: 'about',
         component: () => import('@/views/about-page.vue'),
         meta: {
            requiresAuth: true,
         },
      },
      {
         path: '/maps',
         name: 'maps',
         component: () => import('@/views/maps-page.vue'),
         meta: {
            requiresAuth: true,
         },
      },
      {
         path: '/profile',
         name: 'profile',
         component: () => import('@/views/profile-page.vue'),
         meta: {
            requiresAuth: true,
         },
      },
      {
         path: '/settings',
         name: 'settings',
         component: () => import('@/views/settings-page.vue'),
         meta: {
            requiresAuth: true,
         },
      },
      {
         path: '/login',
         name: 'login',
         component: () => import('@/views/auth/login-page.vue'),
      },
      {
         path: '/auth/callback',
         name: 'cognito-callback',
         component: () => import('@/views/auth/callbacks/cognito-callback-page.vue'),
      },
      {
         path: '/signup',
         name: 'signup',
         component: () => import('@/views/auth/signup-page.vue'),
      },
      {
         path: '/forgot-password',
         name: 'forgot-password',
         component: () => import('@/views/auth/forgot-password-page.vue'),
      },

      {
         path: '/confirm-signup',
         name: 'confirm-signup',
         component: () => import('@/views/auth/confirm-signup-page.vue'),
      },
      ...testRoutes,
   ],
});

router.beforeEach(authGuard);

export default router;
