import type { MaybeRef } from '@vueuse/core';
import { useTitle } from '@vueuse/core';
import { computed, unref } from 'vue';

/* ---------------------------------------------------------------------------------------------- */

/**
 * Set app page title
 */
export function useAppTitle(title?: MaybeRef<string>) {
   const computedTitle = computed(() => {
      const unrefTitle = unref(title);
      return unrefTitle ? `Apaluma - ${unrefTitle}` : 'Apaluma';
   });

   return useTitle(computedTitle);
}
