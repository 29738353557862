import type {
   AccessTokenObjectFields,
   AuthenticatedUser,
   IdTokenObjectFields,
} from '@/types/auth-types.ts';
import type { ApiKey } from '@/types/user/api-keys-types.ts';
import type { UserProfile } from '@/types/user/user-profile-types.ts';
import type { AuthSession, AuthTokens, AuthUser } from '@aws-amplify/auth';
import {
   signOut as cognitoSignOut,
   confirmResetPassword,
   confirmSignIn,
   confirmSignUp,
   fetchAuthSession,
   getCurrentUser,
   resetPassword,
   signIn,
   signInWithRedirect,
   signUp,
} from '@aws-amplify/auth';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

/* ---------------------------------------------------------------------------------------------- */

/**
 * Store: Auth
 * Manages auth state of the user
 */
export const useAuthStore = defineStore('auth', () => {
   const isInitialized = ref(false);

   /*
    * User info
    */
   const accessToken = ref('');
   const accessTokenObject = ref<AccessTokenObjectFields>({});

   const idToken = ref('');
   const idTokenObject = ref<IdTokenObjectFields>({});
   const user = ref<AuthenticatedUser | null>(null);
   const isAuthenticated = ref(false);

   /* ------------------------------------------------------------------------------------------- */

   /*
    * User profile info
    */

   const userProfile = useStorage<UserProfile | null>(
      'apaluma-user-profile', // key
      null, // default
      undefined, // storage, undefined for localStorage
      {
         serializer: StorageSerializers.object, // type of serializer
      },
   );

   const userApiKey = useStorage<ApiKey | null>('apaluma-user-api-key', null, undefined, {
      serializer: StorageSerializers.object,
   });

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Set user
    */
   const setUser = (newUser: AuthUser) => {
      user.value = {
         userId: newUser.userId,
         username: newUser.username,
         email: idTokenObject.value.email || '',
      };

      isAuthenticated.value = true;
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Set auth-token from the session info
    */
   const setToken = (session: AuthSession) => {
      // set the access token
      accessTokenObject.value = session.tokens?.accessToken.payload || {};
      accessToken.value = session.tokens?.accessToken.toString() || '';

      // set the id token
      idTokenObject.value = session.tokens?.idToken?.payload || {};
      idToken.value = session.tokens?.idToken?.toString() || '';
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Init auth store
    */
   const init = async () => {
      try {
         const session = await fetchAuthSession();

         if (session.tokens === undefined) {
            isInitialized.value = false;
            return;
         }
         else {
            setToken(session);
         }

         setUser(await getCurrentUser());

         isInitialized.value = true;
      }
      catch (e) {
         console.error(e);
         isInitialized.value = true;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Login using username and password
    */
   const passwordLogin = async (username: string, password: string) => {
      const { nextStep } = await signIn({ username, password });

      switch (nextStep.signInStep) {
         case 'CONFIRM_SIGN_UP':
            // User needs to confirm their account
            return {
               step: 'CONFIRM_SIGN_UP',
               message: 'Please check your email for confirmation code',
            };

         case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
            // MFA via SMS is required
            return {
               step: 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
               message: 'Please enter the code sent to your phone',
            };

         case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
            // MFA via TOTP (authenticator app) is required
            return {
               step: 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
               message: 'Please enter the code from your authenticator app',
            };

         case 'DONE':
            // Successfully signed in

            await init();

            return {
               step: 'DONE',
               message: 'Successfully signed in',
            };

         default:
            return {
               step: 'ERROR',
               message: 'Unknown error',
            };
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Call this to confirm sign up when user logs in for the first time,
    * confirmation code can be obtained from the email sent while signing up
    */
   const confirmSignInWithCode = async (confirmationCode: string) => {
      try {
         const { isSignedIn } = await confirmSignIn({ challengeResponse: confirmationCode });

         if (isSignedIn) {
            await init();

            return {
               step: 'DONE',
               message: 'Successfully confirmed sign in',
            };
         }
      }
      catch (e) {
         console.error('Error during sign in confirmation:', e);
         throw e;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Sign in using cognito federated login
    */
   const signInWithGoogle = async () => {
      try {
         await signInWithRedirect({
            provider: 'Google',
         });
         // Note: The actual session handling will happen in a separate function
         // that handles the redirect callback, as this redirect will navigate away
         // from the current page
      }
      catch (e) {
         console.error('Error during Google sign in:', e);
         throw e;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Handles the cognito federated sign-in redirect
    * Called with in callback page
    */
   const handleRedirectSignIn = async () => {
      try {
         await init();

         return {
            step: 'DONE',
            message: 'Successfully signed in with Google',
         };
      }
      catch (e) {
         console.error('Error handling redirect sign in:', e);
         throw e;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Sign up user with email and password
    */
   const signUpUser = async (username: string, password: string, email: string) => {
      try {
         const { userId, nextStep } = await signUp({
            username,
            password,
            options: {
               userAttributes: {
                  email,
               },
            },
         });

         if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
            return {
               step: 'CONFIRM_SIGN_UP',
               message: 'Please check your email for confirmation code',
               userId,
            };
         }

         return {
            step: 'ERROR',
            message: 'Unexpected signup step',
         };
      }
      catch (error) {
         console.error('Error during sign up:', error);
         throw error;
      }
   };

   /**
    * Confirm sign up with verification code
    */
   const confirmUserSignUp = async (username: string, confirmationCode: string) => {
      try {
         const { isSignUpComplete } = await confirmSignUp({
            username,
            confirmationCode,
         });

         if (isSignUpComplete) {
            return {
               step: 'DONE',
               message: 'Successfully confirmed sign up',
            };
         }

         return {
            step: 'ERROR',
            message: 'Failed to confirm sign up',
         };
      }
      catch (error) {
         console.error('Error during sign up confirmation:', error);
         throw error;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Sign out user and clear any stored values
    */
   const signOut = async () => {
      try {
         await cognitoSignOut();

         /*
          * Resetting existing values
          */

         isAuthenticated.value = false;
         accessTokenObject.value = {};
         accessToken.value = '';
         idTokenObject.value = {};
         idToken.value = '';

         user.value = null;

         userProfile.value = null;
         userApiKey.value = null;

         isInitialized.value = false;

         console.log('signed out');

         return true;
      }
      catch (e) {
         console.error(e);
      }
      return false;
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Initiates the password reset process for a user
    * @param username The username (email) of the user
    */
   const initiateResetPassword = async (username: string) => {
      try {
         const { nextStep } = await resetPassword({ username });

         console.log(nextStep);

         if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
            return {
               step: 'CONFIRM_RESET_PASSWORD_WITH_CODE',
               message: 'Please check your email for the verification code',
            };
         }

         return {
            step: 'ERROR',
            message: 'Unexpected reset password step',
         };
      }
      catch (error: any) {
         console.error('Error during password reset initiation:', error);
         throw error;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   /**
    * Confirms the password reset with verification code and new password
    * @param username The username (email) of the user
    * @param newPassword The new password
    * @param confirmationCode The verification code received via email
    */
   const confirmPasswordReset = async (
      username: string,
      newPassword: string,
      confirmationCode: string,
   ) => {
      try {
         await confirmResetPassword({
            username,
            newPassword,
            confirmationCode,
         });

         // If no error is thrown, the password reset was successful
         return {
            step: 'DONE',
            message: 'Password reset successful',
         };
      }
      catch (error: any) {
         console.error('Error during password reset confirmation:', error);
         throw error;
      }
   };

   /* ------------------------------------------------------------------------------------------- */

   return {
      // state
      accessTokenObject,
      accessToken,
      idTokenObject,
      idToken,
      user,
      isAuthenticated,
      isInitialized,

      // persisted states
      userProfile,
      userApiKey,

      // actions
      init,
      setUser,
      passwordLogin,
      confirmSignInWithCode,
      signInWithGoogle,
      handleRedirectSignIn,
      signOut,
      signUpUser,
      confirmUserSignUp,
      initiateResetPassword,
      confirmPasswordReset,
   };
});
