<script setup lang="ts">
import BreakpointIndicator from '@/components/breakpoint-indicator.vue';
import { useAppTitle } from '@/composables/use-app-title.ts';
import { Notification, Notivue, NotivueSwipe } from 'notivue';
import { computed } from 'vue';
import { RouterView } from 'vue-router';

/* ---------------------------------------------------------------------------------------------- */
/*
 * Description: Vue app's bootstrap sfc
 */

useAppTitle('Home');

const isDev = computed(() => import.meta.env.DEV);

/* ---------------------------------------------------------------------------------------------- */
</script>

<template>
   <RouterView v-slot="{ Component }">
      <template v-if="Component">
         <Transition mode="out-in">
            <Suspense>
               <!-- main content -->
               <component :is="Component"></component>

               <!-- loading state -->
               <template #fallback>
                  Loading...
               </template>
            </Suspense>
         </Transition>

         <!-- notivue toast -->
         <Notivue v-slot="item">
            <NotivueSwipe :item="item">
               <Notification :item="item" />
            </NotivueSwipe>
         </Notivue>
      </template>
   </RouterView>

   <BreakpointIndicator v-if="isDev" />
</template>

<style scoped>
</style>
