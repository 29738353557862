import type { App } from 'vue';
import { useAppVersion } from '@/composables/use-app-version';

export const VersionPlugin = {
   install: (app: App) => {
      const { version, buildTime } = useAppVersion();

      app.config.globalProperties.$version = version;
      app.config.globalProperties.$buildTime = buildTime;

      // Also provide it for composition API
      app.provide('appVersion', version);
      app.provide('buildTime', buildTime);
   },
};
