<script setup lang="ts">
import DefaultLayout from '@/layouts/default/default-layout.vue';
import { useRouter } from 'vue-router';

/* ---------------------------------------------------------------------------------------------- */

const router = useRouter();

router.replace('/maps');

/* ---------------------------------------------------------------------------------------------- */
</script>

<template>
   <DefaultLayout>
      <p>Redirecting to maps...</p>
   </DefaultLayout>
</template>
