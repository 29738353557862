import { awsConfig } from '@/aws-exports.ts';
import { NotivuePlugin } from '@/plugins/notivue.ts';
import { Amplify } from 'aws-amplify';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import { VersionPlugin } from './plugins/version.ts';
import router from './router/routes.ts';
import './assets/main.css';

/* ---------------------------------------------------------------------------------------------- */

/*
 * Configure AWS Amplify for cognito authentication
 */
Amplify.configure(awsConfig);

/* ---------------------------------------------------------------------------------------------- */

/*
 * Creates a new vue app instance
 */
const app = createApp(App);

/*
 * Initialize app with pinia and routers
 */
app.use(createPinia());
app.use(router);

/**
 * Register custom plugins
 */
app.use(VersionPlugin);
app.use(NotivuePlugin); // notivue toast

/* ---------------------------------------------------------------------------------------------- */

app.mount('#app');
