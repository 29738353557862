<script setup lang="ts">
import { IconLib } from '@/assets/icon-library.ts';
import SlimSidebar from '@/layouts/default/sidebar/slim-sidebar.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';

/* ---------------------------------------------------------------------------------------------- */

const isSidebarOpen = ref(false);

/* ---------------------------------------------------------------------------------------------- */
</script>

<template>
   <div class="flex w-screen h-dvh">
      <!-- narrow sidebar -->
      <section
         class="fixed lg:sticky bg-neutral shrink-0 w-[5rem] h-dvh text-white top-0 left-0 z-[100]
         transition-all duration-200"
         :class="isSidebarOpen
            ? 'translate-x-0 shadow-lg lg:shadow-none'
            : '-translate-x-full lg:translate-x-0'"
      >
         <SlimSidebar />
      </section>

      <!-- sm screen sidebar backdrop -->
      <div
         class="fixed inset-0 bg-neutral/50 size-full z-[90]"
         :class="isSidebarOpen ? 'flex lg:hidden' : 'hidden' "
         @click="isSidebarOpen = false"
      ></div>

      <!-- main area -->
      <section
         class="size-full"
      >
         <!-- menu toggle button -->
         <button
            class="toggle-button"
            @click="isSidebarOpen = !isSidebarOpen"
         >
            <Icon
               :icon="IconLib.menuToggle.menu"
               class="text-white size-6"
            />
         </button>

         <div class="relative overflow-auto size-full">
            <slot />
         </div>
      </section>
   </div>
</template>

<style scoped lang="postcss">
.toggle-button{
   @apply z-40 md:z-[60] absolute top-sm md:top-md left-sm md:left-md
   flex lg:hidden items-center justify-center
   size-12 md:size-14
   rounded-full bg-primary hover:bg-primary-dark
}
</style>
