import type { ResourcesConfig } from 'aws-amplify';

export const awsConfig: ResourcesConfig = {
   Auth: {
      Cognito: {
         userPoolClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
         userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
         loginWith: {
            oauth: {
               domain: import.meta.env.VITE_COGNITO_DOMAIN,
               scopes: ['email', 'openid', 'phone'],
               responseType: 'code',
               redirectSignIn: [
                  'https://apollo11.apaluma.com/auth/callback',
                  'https://apollo.apaluma.com/auth/callback',
                  'https://platform.apaluma.com/auth/callback',

                  import.meta.env.VITE_REDIRECT_SIGN_IN,
               ],
               redirectSignOut: [
                  'https://apollo11.apaluma.com',
                  'https://apollo.apaluma.com',
                  'https://platform.apaluma.com',
                  import.meta.env.VITE_REDIRECT_SIGN_OUT,
               ],
               providers: ['Google'],
            },
         },
      },
   },
};
