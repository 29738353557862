import type { App } from 'vue';
import { createNotivue } from 'notivue';
import 'notivue/animations.css';
import 'notivue/notification.css';

export const NotivuePlugin = {
   install: (app: App) => {
      const toastNotivue = createNotivue({
         position: 'top-right',
         notifications: {
            global: {
               duration: 3000,
            },
         },
      });

      app.use(toastNotivue);
   },
};
