import { ref } from 'vue';
import pkg from '../../package.json';

export function useAppVersion() {
   const version = ref(pkg.version);
   const buildTime = ref(new Date().toISOString());

   return {
      version,
      buildTime,
   };
}
